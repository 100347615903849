import { SVGProps } from 'react'

export function RiComputerLine(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M4 16h16V5H4v11zm9 2v2h4v2H7v-2h4v-2H2.992A.998.998 0 0 1 2 16.993V4.007C2 3.451 2.455 3 2.992 3h18.016c.548 0 .992.449.992 1.007v12.986c0 .556-.455 1.007-.992 1.007H13z'
      ></path>
    </svg>
  )
}

export function BiPrinterFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
      <g fill='currentColor'>
        <path d='M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z'></path>
        <path d='M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1z'></path>
      </g>
    </svg>
  )
}

export function GameIconsRolledCloth(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 512 512' {...props}>
      <path
        fill='currentColor'
        d='M165.446 34.793c-23.17.023-45.634 12.97-54.612 36.323l-83.67 326.167c-12.673 94.537 81.04 88.742 137.957 65.396c81.422-33.396 181.723-29.213 263.244-8.26l6.45-17.218c-7.38-2.638-15.334-5.988-22.252-8.039c.473-4.364.955-8.72 1.437-13.074l23.038 4.118l3.234-18.1c-8.074-1.441-16.147-2.885-24.221-4.328a4816.63 4816.63 0 0 1 1.87-16.189l22.134 3.278l2.693-18.186c-7.548-1.12-15.098-2.238-22.647-3.355c.456-3.765.91-7.53 1.375-11.29c7.615 1.092 15.231 2.183 22.847 3.273l2.607-18.2l-23.164-3.316c.46-3.593 1.29-9.988 1.76-13.577l22.781 2.55l2.045-17.57a56812.69 56812.69 0 0 1-22.402-2.508c.783-5.767 1.917-11.182 2.728-16.943c7.67 1.12 15.341 2.244 23.012 3.368l2.31-17.139c-7.683-1.127-15.366-2.25-23.05-3.374c.792-5.415 1.252-10.129 2.071-15.542c7.074 1.264 14.149 2.528 21.223 3.79l3.232-18.1l-21.654-3.866c.736-4.676 1.473-9.35 2.23-14.026c6.978 1.673 13.955 3.347 20.932 5.022L465.276 208c-7.401-1.778-14.803-3.554-22.204-5.33a2809.25 2809.25 0 0 1 2.132-12.477c6.98 1.583 13.961 3.165 20.942 4.746l4.064-17.93c-7.271-1.65-14.543-3.298-21.815-4.946c.769-4.267 1.55-8.535 2.342-12.805l20.742 5.151l4.431-17.843l-21.751-5.405c.741-3.847 1.494-7.696 2.254-11.548l20.28 5.014l4.413-17.849l-21.057-5.207a2444.47 2444.47 0 0 1 2.571-12.374c8.386 2.41 13.13 2.364 21.41 4.99L486 88.456c-83.808-26.776-179.25-33.22-244.192-6.453c-24.337 114.036-37.305 221.4-68.032 338.64c-3.407 13-14.47 21.89-27.342 28.064c-27 11.608-64.033 13.778-84.63-4.91c-10.971-10.34-16.174-27.036-12.467-47.579c2.303-12.762 10.883-21.986 20.834-26.378c19.749-7.074 43.492-4.25 58.893 7.95c12.463 9.302 12.318 38.283-3.882 31.82c-9.639-6.17-1.964-11.851-8.615-17.378c-11.6-7.428-26.42-10.872-38.972-5.57c-5.564 2.455-8.887 5.737-10.166 12.822c-2.94 16.29.685 24.996 6.985 30.933c18.333 13.49 45.279 10.495 64.068 1.712c10.045-4.82 16.277-11.436 17.511-16.147c30.538-116.518 43.443-224.123 68.293-339.964c-11.796-28.344-35.67-41.247-58.84-41.225z'
      ></path>
    </svg>
  )
}

export function IcRoundEquals(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M18 9.998H6a1 1 0 1 1 0-2h12a1 1 0 0 1 0 2zm0 6H6a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2z'
      ></path>
    </svg>
  )
}

export function RiSave3Fill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z'
      ></path>
    </svg>
  )
}

export function RiEqualizerFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17z'
      ></path>
    </svg>
  )
}

export function MingcuteColorPickerFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z'></path>
        <path
          fill='currentColor'
          d='M20.477 3.511a3 3 0 0 0-4.243 0l-1.533 1.533a2.991 2.991 0 0 0-3.41.581l-.713.714a2 2 0 0 0 0 2.829l-6.486 6.485a3 3 0 0 0-.878 2.122v1.8a1.2 1.2 0 0 0 1.2 1.2h1.8a3 3 0 0 0 2.12-.88l6.486-6.484a2 2 0 0 0 2.829 0l.714-.715a2.991 2.991 0 0 0 .581-3.41l1.533-1.532a3 3 0 0 0 0-4.243ZM5.507 17.067l6.485-6.485l1.414 1.414l-6.485 6.486a1 1 0 0 1-.707.293h-1v-1a1 1 0 0 1 .293-.707Z'
        ></path>
      </g>
    </svg>
  )
}

export function IconParkTwotoneColorFilter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 48 48' {...props}>
      <mask id='svgIDa'>
        <g fill='none' stroke='#fff' strokeLinejoin='round' strokeWidth='4'>
          <path d='M24 40.944A11.955 11.955 0 0 0 32 44c6.627 0 12-5.373 12-12c0-5.591-3.824-10.29-9-11.622'></path>
          <path d='M13 20.378C7.824 21.71 4 26.408 4 32c0 6.627 5.373 12 12 12s12-5.373 12-12c0-1.55-.294-3.03-.828-4.39'></path>
          <path
            fill='#555'
            d='M24 28c6.627 0 12-5.373 12-12S30.627 4 24 4S12 9.373 12 16s5.373 12 12 12Z'
          ></path>
        </g>
      </mask>
      <path fill='currentColor' d='M0 0h48v48H0z' mask='url(#svgIDa)'></path>
    </svg>
  )
}

export function BxBxsNetworkChart(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        d='M19.5 3A2.502 2.502 0 0 0 17 5.5c0 .357.078.696.214 1.005l-1.955 2.199A3.977 3.977 0 0 0 13 8c-.74 0-1.424.216-2.019.566L8.707 6.293l-.023.023C8.88 5.918 9 5.475 9 5a3 3 0 1 0-3 3c.475 0 .917-.12 1.316-.316l-.023.023L9.567 9.98A3.956 3.956 0 0 0 9 12c0 .997.38 1.899.985 2.601l-2.577 2.576A2.472 2.472 0 0 0 6.5 17C5.122 17 4 18.121 4 19.5S5.122 22 6.5 22S9 20.879 9 19.5c0-.321-.066-.626-.177-.909l2.838-2.838c.421.15.867.247 1.339.247c2.206 0 4-1.794 4-4c0-.636-.163-1.229-.428-1.764l2.117-2.383c.256.088.526.147.811.147C20.879 8 22 6.879 22 5.5S20.879 3 19.5 3zM13 14c-1.103 0-2-.897-2-2s.897-2 2-2s2 .897 2 2s-.897 2-2 2z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

export function TablerArrowsRightLeft(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M21 7H3m15 3l3-3l-3-3M6 20l-3-3l3-3m-3 3h18'
      ></path>
    </svg>
  )
}

export function Fa6SolidShieldHalved(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 512 512' {...props}>
      <path
        fill='currentColor'
        d='M256 0c4.6 0 9.2 1 13.4 2.9l188.3 79.9c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.7 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0zm0 66.8v378C394 378 431.1 230.1 432 141.4L256 66.8z'
      ></path>
    </svg>
  )
}

export function MajesticonsColorSwatch(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <g fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19 22a3 3 0 0 0 3-3v-4a3.001 3.001 0 0 0-2.361-2.932L13 18.708v.792c0 .58-.17 1.439-.694 2.167a3.212 3.212 0 0 1-.275.333H19zm-6-4.707l5.738-5.738a3.001 3.001 0 0 0-.445-3.676L16.12 5.707A3 3 0 0 0 13 5v12.294zM9 2a3 3 0 0 1 3 3v14.5c0 .42-.13 1.061-.506 1.583c-.357.496-.957.917-1.994.917H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h4zM7 18a1 1 0 1 0 0-2a1 1 0 0 0 0 2z'
          fill='currentColor'
        ></path>
      </g>
    </svg>
  )
}

export function MaterialSymbolsHomeRepairServiceRounded(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M2 10q0-.825.588-1.413Q3.175 8 4 8h3V6q0-.825.588-1.412Q8.175 4 9 4h6q.825 0 1.413.588Q17 5.175 17 6v2h3q.825 0 1.413.587Q22 9.175 22 10v4h-4v-1q0-.425-.288-.713Q17.425 12 17 12t-.712.287Q16 12.575 16 13v1H8v-1q0-.425-.287-.713Q7.425 12 7 12t-.713.287Q6 12.575 6 13v1H2Zm7-2h6V6H9ZM4 20q-.825 0-1.412-.587Q2 18.825 2 18v-3h4q0 .425.287.712Q6.575 16 7 16t.713-.288Q8 15.425 8 15h8q0 .425.288.712q.287.288.712.288t.712-.288Q18 15.425 18 15h4v3q0 .825-.587 1.413Q20.825 20 20 20Z'
      ></path>
    </svg>
  )
}

export function BxBxsMagicWand(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        d='M11 4l-.5-1l-.5 1l-1 .125l.834.708L9.5 6l1-.666l1 .666l-.334-1.167l.834-.708zm8.334 10.666L18.5 13l-.834 1.666l-1.666.209l1.389 1.181L16.834 18l1.666-1.111L20.166 18l-.555-1.944L21 14.875zM6.667 6.333L6 5l-.667 1.333L4 6.5l1.111.944L4.667 9L6 8.111L7.333 9l-.444-1.556L8 6.5zM3.414 17c0 .534.208 1.036.586 1.414L5.586 20c.378.378.88.586 1.414.586s1.036-.208 1.414-.586L20 8.414c.378-.378.586-.88.586-1.414S20.378 5.964 20 5.586L18.414 4c-.756-.756-2.072-.756-2.828 0L4 15.586c-.378.378-.586.88-.586 1.414zM17 5.414L18.586 7L15 10.586L13.414 9L17 5.414z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

export function Tabler3dCubeSphere(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='m6 17.6l-2-1.1V14m0-4V7.5l2-1.1m4-2.3L12 3l2 1.1m4 2.3l2 1.1V10m0 4v2.5l-2 1.12m-4 2.28L12 21l-2-1.1m2-7.9l2-1.1m4-2.3l2-1.1M12 12v2.5m0 4V21m0-9l-2-1.12M6 8.6L4 7.5'
      ></path>
    </svg>
  )
}

export function BiLightningChargeFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 16' {...props}>
      <path
        fill='currentColor'
        d='M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z'
      ></path>
    </svg>
  )
}

export function MajesticonsAcademicCap(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <g fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.514 3.126a1 1 0 0 1 .972 0l9 5A1 1 0 0 1 22 9v7a1 1 0 1 1-2 0v-5.3l-1 .555v.004l-6.067 3.016a2 2 0 0 1-1.848-.035L2.357 9.479a1 1 0 0 0-.284-.103a1 1 0 0 1 .441-1.25l9-5zM5 13.199V17a1 1 0 0 0 .553.894l6 3a1 1 0 0 0 .894 0l6-3A1 1 0 0 0 19 17v-3.256l-6.083 2.844a2 2 0 0 1-1.805-.056L5 13.2z'
          fill='currentColor'
        ></path>
      </g>
    </svg>
  )
}

export function IconParkTwotoneLayers(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 48 48' {...props}>
      <mask id='svgIDa'>
        <g fill='none' stroke='#fff' strokeLinejoin='round' strokeWidth='4'>
          <path fill='#555' d='M4 11.914L24 19l20-7.086L24 5L4 11.914Z'></path>
          <path
            strokeLinecap='round'
            d='m4 20l20 7l20-7M4 28l20 7l20-7M4 36l20 7l20-7'
          ></path>
        </g>
      </mask>
      <path fill='currentColor' d='M0 0h48v48H0z' mask='url(#svgIDa)'></path>
    </svg>
  )
}

export function MaterialSymbolsArrowForwardIosRounded(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M7.15 21.1q-.375-.375-.375-.888q0-.512.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375q.512 0 .887.375l8.4 8.425q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325L8.9 21.125q-.35.35-.862.35q-.513 0-.888-.375Z'
      ></path>
    </svg>
  )
}

export function IonSend(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 512 512' {...props}>
      <path
        fill='currentColor'
        d='m476.59 227.05l-.16-.07L49.35 49.84A23.56 23.56 0 0 0 27.14 52A24.65 24.65 0 0 0 16 72.59v113.29a24 24 0 0 0 19.52 23.57l232.93 43.07a4 4 0 0 1 0 7.86L35.53 303.45A24 24 0 0 0 16 327v113.31A23.57 23.57 0 0 0 26.59 460a23.94 23.94 0 0 0 13.22 4a24.55 24.55 0 0 0 9.52-1.93L476.4 285.94l.19-.09a32 32 0 0 0 0-58.8Z'
      ></path>
    </svg>
  )
}

export function MaterialSymbolsTranslateRounded(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M13.35 22q-.6 0-.862-.387q-.263-.388-.063-.963l3.65-9.675q.15-.4.563-.688Q17.05 10 17.5 10q.425 0 .85.287q.425.288.575.688l3.65 9.675q.2.575-.062.963q-.263.387-.888.387q-.275 0-.5-.175q-.225-.175-.325-.425l-.85-2.45H15.1l-.875 2.45q-.1.25-.35.425q-.25.175-.525.175Zm2.35-4.8h3.6l-1.75-4.95h-.1ZM7.15 8.55q.4.725.85 1.337q.45.613 1.05 1.263q1.1-1.2 1.825-2.462Q11.6 7.425 12.1 6H2q-.425 0-.712-.287Q1 5.425 1 5t.288-.713Q1.575 4 2 4h6V3q0-.425.288-.713Q8.575 2 9 2t.713.287Q10 2.575 10 3v1h6q.425 0 .712.287Q17 4.575 17 5t-.288.713Q16.425 6 16 6h-1.9q-.525 1.775-1.425 3.45q-.9 1.675-2.225 3.15l2.4 2.45l-.75 2.05L9 14l-4.3 4.3q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.35-4.35q-.675-.775-1.25-1.563q-.575-.787-1.025-1.662Q5.1 8.8 5.35 8.4t.875-.4q.25 0 .525.162q.275.163.4.388Z'
      ></path>
    </svg>
  )
}

export function IcRoundLocalPhone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='m19.23 15.26l-2.54-.29a1.99 1.99 0 0 0-1.64.57l-1.84 1.84a15.045 15.045 0 0 1-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52a2.001 2.001 0 0 0-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07c.53 8.54 7.36 15.36 15.89 15.89c1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z'
      ></path>
    </svg>
  )
}

export function MaterialSymbolsMailRounded(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M4 20q-.825 0-1.412-.587Q2 18.825 2 18V6q0-.825.588-1.412Q3.175 4 4 4h16q.825 0 1.413.588Q22 5.175 22 6v12q0 .825-.587 1.413Q20.825 20 20 20Zm8-7.175q.125 0 .262-.038q.138-.037.263-.112L19.6 8.25q.2-.125.3-.312q.1-.188.1-.413q0-.5-.425-.75T18.7 6.8L12 11L5.3 6.8q-.45-.275-.875-.013Q4 7.05 4 7.525q0 .25.1.437q.1.188.3.288l7.075 4.425q.125.075.263.112q.137.038.262.038Z'
      ></path>
    </svg>
  )
}

export function EnglishIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='21' height='15' viewBox='0 0 21 15' fill='none' {...props}>
      <g clipPath='url(#clip0_3_18)'>
        <mask
          id='mask0_3_18'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='21'
          height='15'
        >
          <path
            d='M19 0H2C0.89543 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H19C20.1046 15 21 14.1046 21 13V2C21 0.89543 20.1046 0 19 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_3_18)'>
          <path
            d='M19 0H2C0.89543 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H19C20.1046 15 21 14.1046 21 13V2C21 0.89543 20.1046 0 19 0Z'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 0H9V7H0V0Z'
            fill='#444379'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1 1V2H2V1H1ZM3 1V2H4V1H3ZM5 1V2H6V1H5ZM7 1V2H8V1H7ZM6 2V3H7V2H6ZM4 2V3H5V2H4ZM2 2V3H3V2H2ZM1 3V4H2V3H1ZM3 3V4H4V3H3ZM5 3V4H6V3H5ZM7 3V4H8V3H7ZM1 5V6H2V5H1ZM3 5V6H4V5H3ZM5 5V6H6V5H5ZM7 5V6H8V5H7ZM6 4V5H7V4H6ZM4 4V5H5V4H4ZM2 4V5H3V4H2Z'
            fill='#A7B6E7'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9 0V1H21V0H9ZM9 2V3H21V2H9ZM9 4V5H21V4H9ZM9 6V7H21V6H9ZM0 8V9H21V8H0ZM0 10V11H21V10H0ZM0 12V13H21V12H0ZM0 14V15H21V14H0Z'
            fill='#ED4C49'
          />
          <path
            d='M19 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V13C0.5 13.8284 1.17157 14.5 2 14.5H19C19.8284 14.5 20.5 13.8284 20.5 13V2C20.5 1.17157 19.8284 0.5 19 0.5Z'
            stroke='black'
            strokeOpacity='0.1'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_3_18'>
          <rect width='21' height='15' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function TurkishIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='21' height='15' viewBox='0 0 21 15' fill='none' {...props}>
      <g clipPath='url(#clip0_3_24)'>
        <mask
          id='mask0_3_24'
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='21'
          height='15'
        >
          <path
            d='M19 0H2C0.89543 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H19C20.1046 15 21 14.1046 21 13V2C21 0.89543 20.1046 0 19 0Z'
            fill='white'
          />
        </mask>
        <g mask='url(#mask0_3_24)'>
          <path
            d='M19 0H2C0.89543 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H19C20.1046 15 21 14.1046 21 13V2C21 0.89543 20.1046 0 19 0Z'
            fill='#E70B17'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M14.002 8.65201L12.84 9.262L13.062 7.969L12.122 7.052L13.422 6.862L14.002 5.686L14.582 6.863L15.882 7.053L14.942 7.968L15.164 9.261'
            fill='white'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.5 11C11.433 11 13 9.433 13 7.5C13 5.567 11.433 4 9.5 4C7.567 4 6 5.567 6 7.5C6 9.433 7.567 11 9.5 11ZM10.5 10C11.88 10 13 8.88 13 7.5C13 6.12 11.88 5 10.5 5C9.12 5 8 6.12 8 7.5C8 8.88 9.12 10 10.5 10Z'
            fill='white'
          />
          <path
            d='M19 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V13C0.5 13.8284 1.17157 14.5 2 14.5H19C19.8284 14.5 20.5 13.8284 20.5 13V2C20.5 1.17157 19.8284 0.5 19 0.5Z'
            stroke='black'
            strokeOpacity='0.1'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_3_24'>
          <rect width='21' height='15' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export function HamburgerMenu(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
      <path
        d='M19 7H5'
        stroke='#f3f3f3'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M19 12H9'
        stroke='#f3f3f3'
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M19 17H13'
        stroke='#f3f3f3'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export function RightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className='scale-110 ml-2 mt-[1px] drop-shadow'
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='none'
      {...props}
    >
      <path
        d='M1 9L5 5L1 1'
        stroke='#F3F3F3'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export function MdiMapMarker(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7Z'
      ></path>
    </svg>
  )
}

export function MaterialSymbolsPlayArrowRounded(
  props: SVGProps<SVGSVGElement>,
) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' {...props}>
      <path
        fill='currentColor'
        d='M9.525 18.025q-.5.325-1.013.037Q8 17.775 8 17.175V6.825q0-.6.512-.888q.513-.287 1.013.038l8.15 5.175q.45.3.45.85t-.45.85Z'
      ></path>
    </svg>
  )
}

export function PhDotsNineBold(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 256 256' {...props}>
      <path
        fill='currentColor'
        d='M76 60a16 16 0 1 1-16-16a16 16 0 0 1 16 16Zm52-16a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm68 32a16 16 0 1 0-16-16a16 16 0 0 0 16 16ZM60 180a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm68 0a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm68 0a16 16 0 1 0 16 16a16 16 0 0 0-16-16ZM60 112a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm68 0a16 16 0 1 0 16 16a16 16 0 0 0-16-16Zm68 0a16 16 0 1 0 16 16a16 16 0 0 0-16-16Z'
      ></path>
    </svg>
  )
}

export function CarbonDataBackup(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
      <circle cx='7' cy='7' r='1' fill='currentColor'></circle>
      <circle cx='7' cy='15' r='1' fill='currentColor'></circle>
      <circle cx='7' cy='23' r='1' fill='currentColor'></circle>
      <path
        fill='currentColor'
        d='M12 26H4v-6h8v-2H4v-6h18V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v22a2 2 0 0 0 2 2h8ZM4 4h16v6H4Z'
      ></path>
      <path
        fill='currentColor'
        d='M28 17v2.413A6.996 6.996 0 1 0 22 30v-2a5 5 0 1 1 4.576-7H24v2h6v-6Z'
      ></path>
    </svg>
  )
}

export function LaHammer(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' viewBox='0 0 32 32' {...props}>
      <path
        fill='currentColor'
        d='m7.063 2l2.5 1.813c4.714 3.398 6.144 6.73 6.437 7.468l-.906.906v.032l-.688.687L2.687 25.062l-.687.72l4.25 4.25l.719-.75l12.406-12.845l.313-.375l.093-.062l.969-.344l.188.188l-.375.906l-.282.625l3.313 3.313l6.375-6.375l-2.844-2.844l-.469-.438l-.593.219l-.907.344l-.218-.219l.312-1l.125-.406l-.188-.375S24.134 7.637 21.75 5.75C19.367 3.863 15.594 2 10.156 2zm6.03 2.25c3.368.5 5.798 1.766 7.438 3.063C22.297 8.71 23 9.842 23.22 10.219l-.375 1.156l-.157.563l.407.437l1.593 1.594l.625-.25l.875-.344l.938.938l-3.531 3.53l-.938-.937l.375-.906l.282-.625l-.5-.469l-1.125-1.125l-.438-.469l-.625.22l-1.781.718l-.188.063l-.718-.688l-.72-.719l1.157-1.156l-.219-.594s-1.343-3.394-5.062-6.906zm2.72 10.063l1.437 1.437L6.219 27.156L4.812 25.75z'
      ></path>
    </svg>
  )
}
